import { computed, inject, Injectable } from '@angular/core';
import { Gallery, gallerySessionStorageKeys, Media, MediaTypeFilterValue } from './gallery.model';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, EMPTY, filter, map, Observable, of, pipe, switchMap, tap } from 'rxjs';
import { GalleryApi } from './gallery.api';
import { tapResponse } from '@ngrx/operators';
import { Router } from '@angular/router';

export interface GalleryState {
    gallerySlug: string | null;
    gallery: Gallery | null;
    galleryIsLoading: boolean;
    galleryQueryParams: Record<string, string>;
    selectedMediaType: MediaTypeFilterValue;
    selectedMediaId: string | null;
    selectedMediaVideoCurrentTime: number;
}

const initialState: GalleryState = {
    gallerySlug: null,
    gallery: null,
    galleryIsLoading: false,
    galleryQueryParams: {},
    selectedMediaType: 'ALL',
    selectedMediaId: null,
    selectedMediaVideoCurrentTime: 0,
};

@Injectable({
    providedIn: 'root',
})
export class GalleryStore {
    private router = inject(Router);
    private api = inject(GalleryApi);

    readonly state = signalState(initialState);
    readonly gallerySlug = this.state.gallerySlug;
    readonly gallery = this.state.gallery;
    readonly galleryIsLoading = this.state.galleryIsLoading;
    readonly galleryIsLoaded = computed(() => this.state.gallery() !== null);
    readonly galleryQueryParams = this.state.galleryQueryParams;
    readonly media = computed(() => this.state.gallery()?.media ?? []);
    readonly mediaTotal = computed(() => this.media().length);
    readonly selectedMediaType = this.state.selectedMediaType;
    readonly mediaImages = computed(() => this.media().filter((m) => m.type === 'IMAGE'));
    readonly mediaImagesTotal = computed(() => this.mediaImages().length);
    readonly mediaVideos = computed(() => this.media().filter((m) => m.type === 'VIDEO'));
    readonly mediaVideosTotal = computed(() => this.mediaVideos().length);
    readonly filteredMedia = computed(() => {
        switch (this.selectedMediaType()) {
            case 'IMAGE':
                return this.mediaImages();
            case 'VIDEO':
                return this.mediaVideos();
            default:
                return this.media();
        }
    });
    readonly filteredMediaTotal = computed(() => this.filteredMedia().length);
    readonly mediaTotals = computed(() => ({
        ALL: this.mediaTotal(),
        IMAGE: this.mediaImagesTotal(),
        VIDEO: this.mediaVideosTotal(),
    }));
    readonly selectedMediaId = this.state.selectedMediaId;
    readonly selectedMediaVideoCurrentTime = this.state.selectedMediaVideoCurrentTime;
    readonly selectedMedia = computed(() => {
        if (!this.selectedMediaId()) {
            return undefined;
        }

        return this.filteredMedia().find((m) => m.mediaId === this.selectedMediaId());
    });
    readonly selectedMediaLoaded = computed(() => !!this.selectedMedia());
    readonly selectedMediaIndex = computed(() => {
        if (!this.selectedMediaId()) {
            return -1;
        }

        return this.filteredMedia().findIndex((m) => m.mediaId === this.selectedMediaId());
    });

    readonly loadGallery = rxMethod<string>(pipe(switchMap((slug: string) => this.getGallery(slug))));

    readonly loadMedia = rxMethod<{ gallerySlug: string; mediaId: string }>(
        pipe(
            tap(() => patchState<GalleryState>(this.state, { selectedMediaId: null })),
            switchMap(({ gallerySlug, mediaId }) =>
                this.getGallery(gallerySlug).pipe(
                    map((gallery) => {
                        const foundMedia = gallery.media.find((m) => m.mediaId === mediaId);

                        if (foundMedia) {
                            return foundMedia;
                        } else {
                            throw new Error('Media not found');
                        }
                    }),
                ),
            ),
            filter((media) => !!media),
            tap((media: Media) => {
                this.setSelectedMediaId(media.mediaId);
            }),
            catchError(() => {
                this.notFoundRedirect();

                return EMPTY;
            }),
        ),
    );

    private readonly recordGalleryView = rxMethod<string>(
        pipe(
            filter(() => {
                let viewRecorded = false;

                try {
                    viewRecorded = JSON.parse(
                        sessionStorage.getItem(gallerySessionStorageKeys.viewRecorded) ?? 'false',
                    );
                } catch (e) {}

                return !viewRecorded;
            }),
            switchMap((gallerySlug: string) => this.api.recordGalleryEvent(gallerySlug, 'view')),
            tap(() => sessionStorage.setItem(gallerySessionStorageKeys.viewRecorded, JSON.stringify(true))),
        ),
    );

    private getGallery(slug: string): Observable<Gallery> {
        if (this.gallerySlug() === slug && this.gallery()) {
            return of(this.gallery()!);
        }

        patchState<GalleryState>(this.state, { gallerySlug: null, gallery: null, galleryIsLoading: true });

        return this.api.loadGallery(slug).pipe(
            tapResponse({
                next: (gallery) => {
                    if (gallery.media.length) {
                        patchState<GalleryState>(this.state, { gallery, gallerySlug: slug });
                    } else {
                        this.emptyRedirect();
                    }

                    this.initGallerySessionStorage(slug);
                    this.recordGalleryView(slug);
                },
                error: () => this.notFoundRedirect(),
                finalize: () => patchState(this.state, { galleryIsLoading: false }),
            }),
        );
    }

    private notFoundRedirect() {
        this.router.navigate(['/error', 'not-found'], { skipLocationChange: true });
    }

    private emptyRedirect() {
        this.router.navigate(['/error', 'empty'], { skipLocationChange: true });
    }

    /**
     * Initialize the gallery session storage with the current gallery slug.
     * This is used to determine if the gallery has changed and reset the session storage.
     */
    private initGallerySessionStorage(gallerySlug: string) {
        if (sessionStorage.getItem(gallerySessionStorageKeys.slug) !== gallerySlug) {
            Object.values(gallerySessionStorageKeys).forEach((key) => sessionStorage.removeItem(key));
            sessionStorage.setItem(gallerySessionStorageKeys.slug, gallerySlug);
        }
    }

    /**
     * State Setters
     */

    setSelectedMediaType(value: MediaTypeFilterValue) {
        patchState(this.state, { selectedMediaType: value });
    }

    setSelectedMediaId(value: string | null) {
        patchState(this.state, { selectedMediaId: value });
    }

    setGalleryQueryParams(value: Record<string, string>) {
        patchState(this.state, { galleryQueryParams: value });
    }

    setSelectedMediaVideoCurrentTime(value: number) {
        patchState(this.state, { selectedMediaVideoCurrentTime: value });
    }
}
