import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Gallery } from './gallery.model';

export type GalleryEvent = 'view' | 'download';

@Injectable({
    providedIn: 'root',
})
export class GalleryApi {
    private readonly http = inject(HttpClient);

    loadGallery(slug: string) {
        return this.http.get<Gallery>(`/g/${slug}/a/gallery`);
    }

    getGalleryDownloadLink(slug: string) {
        return `/g/${slug}/a/gallery/download`;
    }

    recordGalleryEvent(slug: string, eventType: GalleryEvent) {
        return this.http.put(`/g/${slug}/a/gallery/events/${eventType}`, {});
    }

    recordMediaEvent({ slug, mediaId, eventType }: { slug: string; mediaId: string; eventType: GalleryEvent }) {
        return this.http.put(`/g/${slug}/a/gallery/${mediaId}/events/${eventType}`, {});
    }
}
