export interface Gallery {
    description: string;
    instructions: string;
    media: Media[];
    name: string;
    slug: string;
}

export interface Media {
    assetUrls: {
        detail: string;
        download: string;
        thumbnail: string;
    };
    captured: string;
    description: string;
    duration: string;
    filename: string;
    height: number;
    mediaId: string;
    notes: string;
    slug: string;
    tags: string[];
    title: string;
    type: 'IMAGE' | 'VIDEO';
    width: number;
}

export const mediaTypeFilterValues = ['ALL', 'IMAGE', 'VIDEO'] as const;
export type MediaTypeFilterValue = (typeof mediaTypeFilterValues)[number];

export const galleryQueryParams = {
    type: 'type',
};

export const gallerySessionStorageKeys = {
    slug: 'gallerySlug',
    viewRecorded: 'galleryViewRecorded',
    viewedMediaIds: 'galleryViewedMediaIds',
};
