import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { removeQueryParamsAndHashFromUrl } from './utils/url.util';

@Injectable({
    providedIn: 'root',
})
export class GfTitleStrategy extends TitleStrategy {
    private previousUrl = '';

    constructor(private readonly title: Title) {
        super();
    }

    override updateTitle(snapshot: RouterStateSnapshot): void {
        // This check ensures the title updates only when the url has changed. Otherwise, query params changing will
        // trigger a title change and can possibly override any manual title change done in a component.
        const url = removeQueryParamsAndHashFromUrl(snapshot.url);
        if (url !== this.previousUrl) {
            const title = this.buildTitle(snapshot);
            if (title !== undefined) {
                this.title.setTitle(this.addSuffixToTitle(title));
            } else {
                this.title.setTitle('Greenfly');
            }

            this.previousUrl = url;
        }
    }

    /**
     * Set the title directly without building it from the route snapshot. This is useful for setting the title
     * explicitly from a component using state.
     */
    setTitle(title: string): void {
        this.title.setTitle(this.addSuffixToTitle(title));
    }

    private addSuffixToTitle(title: string): string {
        return `${title} - Greenfly`;
    }
}
